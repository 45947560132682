import React from 'react';
import { IEditorContent, IEditorState } from './Editor';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import PitchDialog, { IPitchState, IPitchProps, getDefaultVoiceSettings } from './PitchDialog';
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import SettingsIcon from '@material-ui/icons/Settings';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import getAuthorizationHeader from '../Authenticate';

const styles = {
    root: {
      width: '80%',
      'margin-left': '0',
      'margin-top': '15px',
      'border-radius': '10px',
    },
    table: {
      minWidth: '200',
    },    
  }




interface IVoiceSettings
{
    Property: string,
    Value: string | number;
}

const setVoice = async ( voice: any )  => {
  const Authorization: string = await getAuthorizationHeader();
  const response = await fetch('api/editor', {
      method: 'PUT',
      mode: 'same-origin',
      headers: { Authorization, 'Content-Type': 'application/json' },
      redirect: 'follow',
      body: JSON.stringify(voice),
  });
  if (!response.ok) {
      alert('Something failed ' + response.status);
      throw Error('Unable to get editor value');
  }
  return;
}

interface IVoiceState extends IPitchState {
  rows: IVoiceSettings[];
}


export default class VoiceSettings extends React.Component<IPitchProps, IVoiceState> {
    state: IVoiceState = { ...PitchDialog.defaultProps,   voiceItems: <></>, entityKey: '', rows: []  };

    constructor(props: IPitchProps)
    {
        super(props);
        this.state = {...props, rows: []};
        
    }

    static defaultVoice: IPitchState = { voice: undefined, open: false };
    static getDefaultVoice = async (): Promise<IPitchState> => {
      if(VoiceSettings.defaultVoice.voice == undefined || VoiceSettings.defaultVoice.voice === '')
      {
        VoiceSettings.defaultVoice = await getDefaultVoiceSettings();
      }
      return VoiceSettings.defaultVoice;
    }

    static setDefaultVoice = async (voice: IPitchState) => {
      VoiceSettings.defaultVoice = voice;
      const value = { Language: voice.language, Voice: voice.voice, Style: voice.style, Pitch: voice.pitch, Rate: voice.rate };
      await setVoice(value); 
    }
    isEmpty = (): boolean => {
        return (this.state.voice === undefined || this.state.voice === '')
    }

    getRows = (voice: IPitchState): IVoiceSettings[] => {
      const rows: IVoiceSettings[] = [
        { Property: "Language", Value: voice.language as string },
        { Property: "Voice", Value: voice.voice as string },
        { Property: "Style", Value: voice.style as string },
        { Property: "Pitch", Value: voice.pitch as number },
        { Property: "Rate", Value: voice.rate as number },
      ];
      return rows;      
    }
    componentDidMount = async () => {
        if(this.state.rows.length == 0)
        {
          const voice: IPitchState = await VoiceSettings.getDefaultVoice();
          if(voice.voice === undefined || voice.voice === '')
          {
            this.setState({ open: false });
          } else
          {
            const rows: IVoiceSettings[] = this.getRows(voice);
            this.setState({...voice,  open: true, rows})
          }
        }

    }

    modifyInstance = async (cancelled: boolean, speech: IPitchState) => {
      if(!cancelled)
      {
        await VoiceSettings.setDefaultVoice(speech);
        this.setState({...speech, open: true, rows: this.getRows(speech)});
      }
    }

    public openDialog = () => {
      let dialog = this.refs.dialog as PitchDialog;
      if(this.isEmpty() || this.state.language === undefined)
      {
          dialog.setState({ open: true });
      } else
      {
        const voiceItems = dialog.updateVoicesByLocale(this.state.language as string);
        dialog.setState({ ...this.state, open: true, voiceItems });
      }
    }

    render = (): JSX.Element => {
        return (
            <div>
            <Accordion style={styles.root} elevation={15}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
          <Typography>Voice Settings
          &nbsp;
          <Tooltip title="Set Default Voice">
          <IconButton onClick={() => this.openDialog()}><SettingsIcon /></IconButton>
          </Tooltip>
          </Typography>
              </AccordionSummary>
              <AccordionDetails>
              <div style={{ display: this.state.open ? 'none' : ''}}>
          <Button variant="contained" color="primary" onClick={() => this.openDialog()}>You need to set default voice</Button>
          </div>
              <TableContainer variant="outlined" elevation={15} component={Paper} style={{ display: this.state.open ? '' : 'none'}}>
            <Table style={styles.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Property</TableCell>
                  <TableCell align="right">Value</TableCell>
      
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.rows.map((row) => (
                  <TableRow key={row.Property}>
                    <TableCell component="th" scope="row">
                      {row.Property}
                    </TableCell>
                    <TableCell align="right">{row.Value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
                  </AccordionDetails>
            </Accordion>
            <PitchDialog open={false} ref="dialog" onAccept={this.modifyInstance} />
          </div>
        );

    }

}

