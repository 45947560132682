import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontIcon } from 'office-ui-fabric-react/lib/Icon';
import { LoginMenu } from './api-authorization/LoginMenu';
import { initializeIcons } from '@uifabric/icons';
import './NavMenu.css';

interface INavState {
    collapsed: boolean
}

const menuItems = [
    {
        iconName: 'Home',
        text: 'Home',
        link: '/',
    }, 
    {
        iconName: "EditNote",
        text: "Editor",
        link: "/editor",
    }, 
]

export class NavMenu extends Component<{}, INavState> {
  static displayName = NavMenu.name;

   constructor(props: {}) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true
    };
  }

  toggleNavbar () {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render () {
    return (
      <header>
            <Navbar className="navbar-dark bg-inverse navbar-expand-sm navbar-toggleable-sm ng-white box-shadow mb-3 bg-primary" light>
          <Container>
            <NavbarBrand className="navbar-brand text-light" tag={Link} to="/">SPeachO</NavbarBrand>
            <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
            <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
              <ul className="navbar-nav flex-grow">
                            {menuItems.map(item => {
                                return (
                                    <>
                                        <NavItem>
                                            <NavLink tag={Link} className="navMenu text-light" to={item.link}><FontIcon iconName={item.iconName} /> {item.text}</NavLink>
                                        </NavItem>
                                    </>

                                );
                            })}

                <LoginMenu>
                </LoginMenu>
              </ul>
            </Collapse>
          </Container>
        </Navbar>
      </header>
    );
  }
}
