import React, { Component, PropsWithChildren } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import { TopHeader } from './TopHeader/TopHeader';

export class Layout extends React.Component<PropsWithChildren, {}> {
  static displayName = Layout.name;

  render () {
    return (
        <div>
            <NavMenu />
            <Container>
                {this.props.children}
            </Container>
        </div>
    );
  }
}
