import React, { Component } from 'react';
import authService from './api-authorization/AuthorizeService'
import AuthorizeRoute from './api-authorization/AuthorizeRoute';

interface IForecast {
    date: string,
    temperatureC: number,
    temperatureF: number,
    summary: string
}

interface IForecastState {
    forecasts: IForecast[],
    loading: boolean
}

export class FetchData extends Component<{}, IForecastState> {
  static displayName = FetchData.name;

  constructor(props: {}) {
    super(props);
    this.state = { forecasts: [], loading: true };
  }

  componentDidMount() {
    this.populateWeatherData();
  }

  static renderForecastsTable(forecasts: IForecast[]) {
    return (
      <table className='table table-striped' aria-labelledby="tabelLabel">
        <thead>
          <tr>
            <th>Date</th>
            <th>Temp. (C)</th>
            <th>Temp. (F)</th>
            <th>Summary</th>
          </tr>
        </thead>
        <tbody>
          {forecasts.map(forecast =>
            <tr key={forecast.date}>
              <td>{forecast.date}</td>
              <td>{forecast.temperatureC}</td>
              <td>{forecast.temperatureF}</td>
              <td>{forecast.summary}</td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }

  render() {
    let contents = this.state.loading
      ? <p><em>Loading...</em></p>
      : FetchData.renderForecastsTable(this.state.forecasts);

    return (
      <div>
        <h1 id="tabelLabel" >Weather forecast</h1>
        <p>This component demonstrates fetching data from the server.</p>
        {contents}
      </div>
    );
  }

    async populateWeatherData() {
        if (!authService.isAuthenticated()) {
            await authService.signIn('');
        }
        const user = await authService.getUser();
        const profile = await authService.userManager?.metadataService.getMetadata();
        console.log("profile:");
        console.trace(profile?.userinfo_endpoint);

        console.trace(user);
        
      const token = await authService.getAccessToken();
      try {
        const response = await fetch('weatherforecast', {
        headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });
        if (response.status === 401) {
          await authService.signIn('');
        }
          const data = await response.json();
          this.setState({ forecasts: data, loading: false });          
      } catch 
      {
        console.log("unable to get Data");
      }

  }
}
