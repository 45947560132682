import React, { Component } from 'react';
import classNames from 'classnames';

import { mergeStyles } from '@uifabric/merge-styles';
// core components
import Header from "./Header/Header.js";
import Footer from "./Footer/Footer.js";
import GridContainer from "./Grid/GridContainer.js";
import GridItem from "./Grid/GridItem.js";
import Button from "./CustomButtons/Button.js";
import HeaderLinks from "./Header/HeaderLinks.js";
import Parallax from "./Parallax/Parallax.js";

import styles from "../assets/jss/material-kit-react/views/landingPage.js";

import { Stack } from 'office-ui-fabric-react/lib/Stack';


const dashboardRoutes: any[] = ['/counter'];

interface IStyle {
    container?: Record<string, string>[],
    title?: Record<string, string>[],
    //subtitle?: Record<string, string>[],
    //main?: Record<string, string>[],
    //mainRaised?: Record<string, string>[],
}

export const MainPanelNumericalWidth = 1100;
export const MainPanelWidth = `${MainPanelNumericalWidth}px`;
export const MainPanelInnerContent = mergeStyles({
    marginRight: 'auto',
    marginLeft: 'auto',
    padding: '32px',
    width: MainPanelWidth,
});

export interface ISamplesProps {
    backgroundColor: string;
    textColor: string;
}

export class Home extends Component<ISamplesProps, {}> {
  static displayName = Home.name;
    rest: any;
    //classes: IStyle; //Record<"styles", string>;

    //useStyles = makeStyles(theme => ({ styles } )); // makeStyles({ styles });
    

    constructor(props: any) {
        super(props);
        const { ...rest } = props;
        this.rest = rest;
    }

    render() {


        return (
            <div>
                <Stack>
                    <p>Hello</p>
                </Stack>
            </div>
            );
            }
            }
