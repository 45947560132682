import React, { Component, DOMElement } from 'react';
import {
    convertFromRaw,
    convertToRaw,
    CompositeDecorator,
    Editor,
    EditorState,
    DraftDecorator,
    Modifier,
    RawDraftContentState,
    ContentBlock,
    ContentState,
    Entity,
    RichUtils,
    DraftEditorCommand,
    DraftHandleValue,
    SelectionState
} from 'draft-js';

import { MessageBarButton, MessageBar, MessageBarType } from 'office-ui-fabric-react';

import ComboCountries from './Countries';

import getAuthorizationHeader from '../Authenticate';

import 'bootstrap/dist/css/bootstrap.css';

import PitchDialog, { IPitchState, IPitchProps } from './PitchDialog'
import { Dialog } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import SettingsVoiceIcon from '@material-ui/icons/SettingsVoice';
import FormatBoldIcon from '@material-ui/icons/FormatBold';
import FormatItalicIcon from '@material-ui/icons/FormatItalic';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Tooltip from '@material-ui/core/Tooltip';
import UndoIcon from '@material-ui/icons/Undo';
import RedoIcon from '@material-ui/icons/Redo';
import SaveIcon from '@material-ui/icons/Save';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DeleteIcon from '@material-ui/icons/Delete';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import SettingsIcon from '@material-ui/icons/Settings';

import Typography from '@material-ui/core/Typography';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { emitKeypressEvents } from 'readline';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { Spa } from '@material-ui/icons';
import VoiceSettings from './VoiceSettings';
import PlayerDialog, { getTempToken } from './PlayerDialog';
import Badge from '@material-ui/core/Badge';
import { DEFAULT_MAX_VERSION } from 'tls';


const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}))(Tooltip);





interface IEditorProps {


}

export interface IEditorState {
    editorState: EditorState;
    currentToolTip: string,
    isSaved: boolean,
}

export interface IEditorContent {
    lastDocument: string;
    Language: (string | null);
    Voice: (string | null);
    Style: (string | null);
    Pitch: number;
    Rate: number;

}

const getContent = async (): Promise<IEditorContent> => {
    const Authorization: string = await getAuthorizationHeader();
    console.log('Before fatch');
    const response = await fetch('api/editor', {
        method: 'GET',
        mode: 'same-origin',
        headers: { Authorization },
        redirect: 'follow',
    });
    if (!response.ok) {
        alert('Something failed ' + response.status);
        throw Error('Unable to get editor value');
    }

    const result: IEditorContent = await response.json();

    return result;



}

export default class LanguageEditor extends Component<IEditorProps, IEditorState> {

    constructor(props: IEditorProps) {
        super(props);
        //let content = '';
        //getContent().then(data => content = data.lastDocument);
        //const blocks = convertFromRaw(rawContent2);
        const editorState: EditorState = EditorState.createEmpty(this.decorator)  //EditorState.createWithContent(blocks, this.decorator);
        this.state = { ...this.props, editorState, currentToolTip: "", isSaved: true };
    }

    static defaultProps: IEditorProps = {

    };


    public firstLoad: boolean = true;

    componentDidMount = async () => {
        console.log(`FirstLoad = ${this.firstLoad}`)
        if (this.firstLoad) {
            this.firstLoad = false;
            const last = await getContent();
            if (last.lastDocument && last.lastDocument.length > 10) {
                const blocks = convertFromRaw(JSON.parse(last.lastDocument));
                const editorState: EditorState = EditorState.createWithContent(blocks, this.decorator);
                this.setState({ editorState });
            };

        }
    }

    static getEntityStrategy(mutability: string) {
        return function (contentBlock: ContentBlock, callback: (start: number, end: number) => void, contentState: ContentState): void {
            contentBlock.findEntityRanges((character) => {
                const entityKey = character.getEntity();
                if (entityKey === null) {
                    return false;
                }
                return contentState.getEntity(entityKey).getMutability() === mutability;
            }, callback);
        };
    }



    modifyInstance = (cancelled: boolean, speech: IPitchState) => {
        if (cancelled) {

            if (speech.pitch === -100) {

                const contentState = this.state.editorState.getCurrentContent();
                //Get Entity
                const entity = contentState.getEntity(speech.entityKey as string)
                const data = entity.getData();


                //Unlink Entity
                const newContentState = Modifier.applyEntity(contentState, data.selectionState, null);
                const newEditorState = EditorState.push(this.state.editorState, newContentState, 'apply-entity')

                //const newEditorState = RemoveEntity(this.state.editorState, data);
                this.onChange(newEditorState)
            }
            return;
        }
        Entity.replaceData(speech.entityKey as string, speech);
        this.setState({ isSaved: false });
        return;

    }

    handleKeyCommand(command: DraftEditorCommand, editorState: EditorState): DraftHandleValue {
        const newState = RichUtils.handleKeyCommand(editorState, command);
        console.trace(command);

        if (newState) {
            this.onChange(newState);
            return 'handled';
        }

        return 'not-handled';
    }

    SpeechSpan = (props: any) => {
        const entityInstance = props.contentState.getEntity(props.entityKey);
        const type = entityInstance.getType();
        //const selectionState = this.state.editorState.getSelectionState();

        if (type === "speech") {
            let state = entityInstance.data;
            (props.contentState as ContentState).replaceEntityData(props.entityKey, { ...entityInstance.data, selectionState: props.contentState.getSelectionAfter().merge({ anchorOffset: props.start, focusOffset: props.end, anchorKey: props.blockKey, }), entityKey: props.entityKey, focusKey: props.entityKey })
            let dialog = this.refs.dialog as PitchDialog;
            let first: boolean = state.entityKey !== props.entityKey && state.entityKey === "";
            if (first) {
                VoiceSettings.getDefaultVoice()
                    .then((defaultVoice) => {
                        state.language = defaultVoice.language;
                        state.voice = defaultVoice.voice;
                        state.style = defaultVoice.style;
                        state.pitch = defaultVoice.pitch;
                        state.rate = defaultVoice.rate;
                        state.entityKey = props.entityKey;
                        state.voiceItems = dialog.updateVoicesByLocale(state.language);
                        state.open = true;
                        dialog.setState({ ...state });
                    }
                    );


            }

            //this.setState({ currentToolTip: this.tableDetails(state.entityKey, state) });
            return (
                <span>
                    <span className="speechBlockConfig" data-offset-key={props.offsetkey} id={props.entityKey} >
                        <span style={{ "padding": "4px" }}>{props.children}</span>
                    </span>

                    <span className="dropButton"
                        onClick={() => {
                            const editorState = this.state.editorState;
                            const contentstate = editorState.getCurrentContent();
                            let dialog = this.refs.dialog as PitchDialog;
                            let entity = contentstate.getEntity(props.entityKey);
                            let data = entity.getData();
                            data.voiceItems = dialog.updateVoicesByLocale(data.language);
                            console.log(data.language);
                            console.trace(data.voiceItems);
                            data.open = true;
                            dialog.setState({ ...data });
                        }
                        }>
                        <MoreHorizIcon />
                    </span>
                </span>


            );
        } else {
            return (
                <span data-offset-key={props.offsetkey}>
                    {props.children}
                </span>
            );
        }
    };

    tableDetails = (key: string, state: IPitchProps) => {
        if (!key || key === '')
            return "<p>error</p>";
        let str: string = "<h1>Speech Settings</h1>";
        str += "<table>";
        str += "<tr>"
        str += "<td>Language:</td>"
        str += "<td>" + state.language + "</td>";
        str += "</tr>";
        str += "<tr>";
        str += "<td>Voice:</td>";
        str += "<td>" + state.voice + "</td>";
        str += "</tr>";
        str += "<tr>";
        str += "<td>Style:</td>";
        str += "<td>" + state.style + "</td>";
        str += "</tr>";
        str += "<tr>";
        str += "<td>Pitch:</td>";
        str += "<td>" + state.pitch + "</td>";
        str += "</tr>";
        str += "<tr>";
        str += "<td>Rate:</td>";
        str += "<td>" + state.rate + "</td>";
        str += "</tr>";
        str += "</table>";
        return str;
    }

    decorator: CompositeDecorator = new CompositeDecorator([{
        strategy: LanguageEditor.getEntityStrategy("MUTABLE"),
        component: this.SpeechSpan,
    }]);

    focus = () => { (this.refs.editor as HTMLElement).focus(); }//.focus();

    getEntityAtSelection = (editorState: EditorState) => {
        const selectionState = editorState.getSelection();
        const selectionKey = selectionState.getStartKey();
        const contentstate = editorState.getCurrentContent();

        // The block in which the selection starts
        const block = contentstate.getBlockForKey(selectionKey);

        // Entity key at the start selection
        const entityKey = block.getEntityAt(selectionState.getStartOffset());
        if (entityKey) {
            // The actual entity instance
            const entityInstance = contentstate.getEntity(entityKey);
            const entityInfo = {
                type: entityInstance.getType(),
                mutability: entityInstance.getMutability(),
                data: entityInstance.getData(),
            }
            console.trace(entityInfo.data);
        } else {
            console.log("No entity present at current selection!");
        }
    }

    setEntityAtSelection = (type: string, mutability: any, data: {}) => {
        const editorState = this.state.editorState;
        const contentstate = editorState.getCurrentContent();

        // Get the current selection
        const selectionState = this.state.editorState.getSelection();

        // Returns ContentState record updated to include the newly created DraftEntity record in it's EntityMap.

        let newContentState = contentstate.createEntity(type, mutability, { ...data, selectionState: selectionState, entityKey: "" });

        // Call getLastCreatedEntityKey to get the key of the newly created DraftEntity record.
        const entityKey = contentstate.getLastCreatedEntityKey();



        // Add the created entity to the current selection, for a new contentState
        newContentState = Modifier.applyEntity(
            newContentState,
            selectionState,
            entityKey
        );

        // Add newContentState to the existing editorState, for a new editorState
        const newEditorState = EditorState.push(
            this.state.editorState,
            newContentState,
            'apply-entity'
        );

        this.onChange(newEditorState);
    }

    onChange = async (editorState: EditorState) => {


        let isSaved: boolean = this.state.isSaved;

        //
        // if it is saved, check whether it was modified ot not
        //
        if (this.state.isSaved === true) {
            const currentContent = this.state.editorState.getCurrentContent()
            const newContent = editorState.getCurrentContent()
            isSaved = (currentContent === newContent); // since content is immutable, this will tell if there was a change
        }

        this.setState({ editorState, isSaved });


    };

    onSave = async () => {
        const Authorization: string = await getAuthorizationHeader();
        const response = await fetch('/api/editor', {
            method: 'POST',
            mode: 'same-origin',
            headers: { Authorization, 'Content-Type': 'application/json' },
            redirect: 'follow',
            body: JSON.stringify({ content: JSON.stringify(convertToRaw(this.state.editorState.getCurrentContent())) }),
        });
        if (!response.ok) {
            alert('Something failed ' + response.status);
        } else {
            this.setState({ isSaved: true })
        }
    };

    saveIfChanged = async () => {
        if (!this.state.isSaved) {
            await this.onSave();

        }
    }

    playAudio = () => {
        const player = this.refs.player as PlayerDialog;
        player.setState({ open: true });
    }

    downloadAudio = async () => {
        const url = await getTempToken();
        window.open(url);
    }

    render() {
        return (
            <div>
                <div className="editorButtons">
                    <Tooltip title="Play Text Narration">
                        <Button variant="contained"
                            onClick={async () => { await this.saveIfChanged(); this.playAudio() }}
                        ><PlayArrowIcon aria-label="Narrate Text" /></Button>
                    </Tooltip>
                    <Tooltip title="Apply Speech Pattern...">
                        <Button variant="contained"
                            onClick={() => this.setEntityAtSelection("speech", "MUTABLE", PitchDialog.defaultProps)}
                        ><SettingsVoiceIcon aria-label="Set Selection Voice" /></Button>
                    </Tooltip>
                    <Tooltip title="Bold">
                        <Button variant="contained"
                            onClick={() => { this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, 'BOLD')); }}
                        ><FormatBoldIcon aria-label="Apply Bold" /></Button>
                    </Tooltip>
                    <Tooltip title="Italic">
                        <Button variant="contained"
                            onClick={() => { this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, 'ITALIC')); }}
                        ><FormatItalicIcon aria-label="Apply Italic" /></Button>
                    </Tooltip>
                    <Tooltip title="Undo">
                        <Button variant="contained"
                            onClick={() => { this.onChange(EditorState.undo(this.state.editorState)); }}
                        ><UndoIcon aria-label="Undo" /></Button>
                    </Tooltip>
                    <Tooltip title="Redo">
                        <Button variant="contained"
                            onClick={() => { this.onChange(EditorState.redo(this.state.editorState)); }}
                        ><RedoIcon aria-label="Redo" /></Button>
                    </Tooltip>
                    <Tooltip title="Delete and clean">
                        <Button variant="contained"
                            onClick={async () => { await this.onChange(EditorState.createEmpty(this.decorator)); this.focus(); }}
                        ><DeleteIcon aria-label="Clear editor" /></Button>
                    </Tooltip>
                    <Tooltip title="Download Audio">

                        <Button variant="contained"
                            onClick={async () => { await this.saveIfChanged(); await this.downloadAudio(); }}
                        >
                            <CloudDownloadIcon aria-label="Download Audio" />
                        </Button>
                    </Tooltip>
                    <Tooltip title="Save text">
                        <Button variant="contained"
                            onClick={() => { this.onSave(); }}
                        >
                            <Badge color="secondary" variant="dot" invisible={this.state.isSaved}>
                                <SaveIcon aria-label="Apply" />
                            </Badge>
                        </Button>
                    </Tooltip>
                    <Tooltip title="Configure default voice">
                        <Button variant="contained"
                            onClick={() => {
                                const settings = this.refs.settings as VoiceSettings;
                                settings.openDialog();
                            }}
                        ><SettingsIcon aria-label="Configure default voice" /></Button>
                    </Tooltip>
                </div>


                {/*
                 <div id="content" className="editor">
                <input
                    type="button"
                    value="Get Value"
                    onClick={() => { let entity = this.getEntityAtSelection(this.state.editorState); console.trace(entity) }}
                />
                <input
                    type="button"
                    value="Dump Metadata"
                    onClick={() => { let fullEditor = this.state.editorState.getCurrentContent(); console.log(JSON.stringify(convertToRaw(fullEditor), null, 4)); }}
                /> 
                */}

                <Editor

                    editorState={this.state.editorState}
                    handleKeyCommand={this.handleKeyCommand}
                    onChange={(e) => this.onChange(e)}
                    spellCheck={true}
                    ref="editor"
                />
                <div>
                    <VoiceSettings open={true} ref="settings" />
                </div>
                <PlayerDialog open={false} src="" play={false} ref="player" />
                <PitchDialog open={false} ref="dialog" onAccept={this.modifyInstance}
                />
            </div>

        );
    }
}
