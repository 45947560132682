import React, { ChangeEvent } from 'react';
//import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AddCircleOutlineTwoToneIcon from '@material-ui/icons/AddCircleOutlineTwoTone';
import RemoveCircleOutlineTwoToneIcon from '@material-ui/icons/RemoveCircleOutlineTwoTone';
import Select from '@material-ui/core/Select';
//import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Grid from '@material-ui/core/Grid';
import Slider from '@material-ui/core/Slider';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { Typography } from '@material-ui/core';
import 'bootstrap/dist/css/bootstrap.css';
import Language, {IVoice } from './Language';
import { IEditorContent } from './Editor';
import authService from '../components/api-authorization/AuthorizeService';
import getAuthorizationHeader from '../Authenticate';
//import { stringify } from 'querystring';
//import axios from 'axios';


const languages: Language = new Language();


export interface IPitchProps  {
    pitch?: number;
    rate?: number;
    language?: string;
    voice?: string;
    style?: string;
    onAccept?: ((cancelled: boolean, speech: IPitchState) => void) | undefined;
    open: boolean;
    accordian?: boolean | undefined;
}

export interface IPitchState {
	pitch?: number,
	rate?: number,
	language?: string,
    voice?: string,
    voiceItems?: JSX.Element,
    style?: string,
    open: boolean,
    entityKey?: string
}

const nullAsUndefStr = (value: string |  null): string | undefined => {
    return value == null ? undefined : value;
}

const nullAsUndefInt = (value: number |  null): number | undefined => {
    return value == null ? undefined : value;
}


export const getDefaultVoiceSettings = async (): Promise<IPitchState> => {
    const Authorization: string = await getAuthorizationHeader();
    const response = await fetch('api/editor', {
        method: 'GET',
        mode: 'same-origin',
        headers: { Authorization },
        redirect: 'follow',
    });
    if (!response.ok) {
        alert('Something failed ' + response.status);
        throw Error('Unable to get editor value');
    }
    const editor: any = await response.json();

    const result: IPitchState = { language: nullAsUndefStr(editor.language), voice: nullAsUndefStr(editor.voice),
                                    style: nullAsUndefStr(editor.style), pitch: nullAsUndefInt(editor.pitch),
                                    rate: nullAsUndefInt(editor.rate),
                                    open: true,
                                  };
    return result;
}




export default class PitchDialog extends React.Component<IPitchProps, IPitchState> {

    state: IPitchState = { ...PitchDialog.defaultProps,   voiceItems: <></>, entityKey: ''  };

    static getLocaleOrDefault(): string
    {
        if(Object.getPrototypeOf(navigator.language) === Object.getPrototypeOf(""))
        {
            return navigator.language;
        }
        if(Object.getPrototypeOf(navigator.languages) === Object.getPrototypeOf([""]))
        {
            return navigator.languages[0];
        }
        return "en-US"
    }
	static defaultProps: IPitchProps = {
		pitch: 0,
		rate: 0,
		language: PitchDialog.getLocaleOrDefault(),
        voice: '',
        style: 'general',
        open: false
	};

    constructor(props: IPitchProps)
    {
        super(props);
        this.state = {...props};

    }
	
    componentDidMount()
    {
        let voiceItems: JSX.Element = this.getVoicesByLocale(this.state.language as string);
        this.setState({ ...this.state, voiceItems, voice: this.lastVoice});
    }
    handleClickOpen = () => {
        let currentState: IPitchState = this.state;
        currentState.open = true;
        this.setState(currentState);

      };
    
    handleCloseAccept = (cancel: boolean, remove: boolean) => {
        let currentState: IPitchState = this.state;
        currentState.open = false;
        this.setState(currentState);
        
        if(remove === true)
        {
            currentState.pitch = -100;
        };
        currentState.voiceItems = undefined;
        if(this.props.onAccept !== undefined)
            this.props.onAccept(cancel, currentState);
      };


      handlePitch = (event: React.ChangeEvent<{}>, newValue: number | number[]) => {
          let tempNumber: number = ((newValue as number) !== undefined) ? (newValue as number) : (newValue as number[])[0];
          this.setState({ pitch : tempNumber });
      }

      handleRate = (event: React.ChangeEvent<{}>, newValue: number | number[]) => {
        let tempNumber: number = ((newValue as number) !== undefined) ? (newValue as number) : (newValue as number[])[0];
        this.setState({ rate : tempNumber });
    }

    getVoiceItemString(voice: IVoice): string
    {
        let parts: string[] = voice.ShortName.split("-");
        parts.splice(0,2);
        let str: string = '';
        for(let key: number = 0; key < parts.length; key++)
        {
            str += parts[key].replace("Apollo", "").replace("RUS", "").replace("Neural", "").replace("24k", " (Hi Def)");
        }
        if(voice.Type === "Neural")
        {
            str += " (Neural)";
        }
        return str;

    }
  
    lastVoice: string = ""
    getVoicesByLocale(locale: string): JSX.Element
    {
        let itemsMale: JSX.Element[] = [];
        let itemsFemale: JSX.Element[] = [];
        let voicesByLocale: IVoice[] = languages.getVoicesByLocale(locale);
        voicesByLocale.sort((valueA, valueB) => ( valueA.EnglishName.localeCompare(valueB.EnglishName) ) );
        let voice: string = "";
        for(let key: number = 0; key < voicesByLocale.length; key++)
        {
            let voiceString: string = this.getVoiceItemString(voicesByLocale[key]);
            if(voice === "")
            {
                voice = voicesByLocale[key].ShortName;
            }

            if(voicesByLocale[key].Gender === 'Male')
            {
                itemsMale.push(<option value={voicesByLocale[key].ShortName}>{voiceString}</option>);
            } else
            {
                itemsFemale.push(<option value={voicesByLocale[key].ShortName}>{voiceString}</option>);
            }
        };
        this.lastVoice = voice;
        return (
         <><optgroup label="Female">{itemsFemale}</optgroup><optgroup label="Male">{itemsMale}</optgroup></>
        );
    }  
    
    updateVoicesByLocale = (language: string) =>  {
        let voiceItems: JSX.Element = this.getVoicesByLocale(language);
        console.trace(voiceItems);
        return voiceItems;
    }
    updating: boolean = false;

    render = () => {
        let itemsLanguage: JSX.Element[] = [];
        
        //const voices = languages.getVoices();    
        for(let key: number = 0; key < languages.languages.length; key++)
        {    
            itemsLanguage.push(<option value={languages.languages[key].IetfName}>{languages.languages[key].EnglishName}</option>);
        };

        const voices = languages.getVoices();              

		return (
            <div>
            <Dialog open={this.state.open} onClose={this.handleClickOpen} aria-labelledby="form-dialog-title">
              <DialogTitle id="form-dialog-title">Speech Portion Settings</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Choose pitch, rate, language and voice
                </DialogContentText>
                <Grid container spacing={2}>
                    <Grid item>
                     <FormControl variant="outlined">
                      <InputLabel htmlFor="language-select">Locale</InputLabel>
                        <Select
                            native
                            
                            value={this.state.language}
                            onChange={(event) => {  
                                let language = event.target.value as string;
                                let voiceItems: JSX.Element = this.getVoicesByLocale(event.target.value as string);
                                this.setState({
                                language,
                                voiceItems,
                                voice: this.lastVoice
                             });
                                 }}
                            name="language"
                            label="Locale"
                            inputProps={{
                            id: 'language-select'  }}
                        >
                        {itemsLanguage}
                        </Select>
                     </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item>
                     <FormControl variant="outlined">
                      <InputLabel htmlFor="voice-select">Voice</InputLabel>
                        <Select
                            native
                            value={this.state.voice}
                            onChange={(event) => {  
                                let voice: string = event.target.value as string;
                                let style: string = this.state.style as string;

                                if(voice.indexOf("Neural") === -1)
                                {
                                    style = PitchDialog.defaultProps.style as string;
                                }
                                this.setState({
                                    voice
                                 });
                                }}
                            name="voice-select"
                            label="Voice"
                            inputProps={{
                            id: 'voice-select'  }}
                        >
                        {this.state.voiceItems}
                        </Select>
                     </FormControl>
                    </Grid>
                </Grid>   
                <Grid container spacing={2}>
                    <Grid item>
                     <FormControl variant="outlined" disabled={(this.state.voice as string).indexOf("Neural") === -1}>
                      <InputLabel htmlFor="style-select">Voice Style</InputLabel>
                        <Select
                            native
                            value={this.state.style}
                            onChange={(event, value) => {  
                                this.setState({
                                style: event.target.value as string });
                                }}
                            name="style-select"
                            label="Voice Style"
                            inputProps={{
                                            id: 'style-select'
                                        }}
                                      
                         >
                                        {this.state.voice && this.state.voice !== '' && voices[this.state.voice as any].StyleList.map(value => {
                                            return (
                                                <option value={value} >{value === "cutomerservice" ? "Customer Service" : value.charAt(0).toUpperCase()+value.substring(1)}</option>
                                            );
                                        }) }
                        </Select>
                     </FormControl>
                    </Grid>
                </Grid>                 
                <Grid container spacing={2}>
                  <Grid item onClick={ () => { this.setState( {pitch: this.state.pitch !== undefined ? (this.state.pitch > -50 ? this.state.pitch  - 1 : -50) : PitchDialog.defaultProps.pitch }); } }>
                        <RemoveCircleOutlineTwoToneIcon />
                  </Grid>
                  <Grid item xs>
                        <Slider value={this.state.pitch} aria-labelledby="continuous-slider" max={50} min={-50} step={1} onChange={this.handlePitch} />
                  </Grid>
                  <Grid item onClick={ () => { this.setState( {pitch: this.state.pitch !== undefined ? (this.state.pitch < 50 ? this.state.pitch  + 1 : 50) : PitchDialog.defaultProps.pitch }); } }>
                        <AddCircleOutlineTwoToneIcon />
                  </Grid>
                </Grid>	
                <Typography gutterBottom className="text-center">
                    {this.state.pitch}% Pitch
                </Typography>                  	  

                <Grid container spacing={2}>
                  <Grid item onClick={ () => { this.setState( {rate: this.state.rate !== undefined ? (this.state.rate > -50 ? this.state.rate  - 1 : -50) : PitchDialog.defaultProps.rate}); } }>
                        <RemoveCircleOutlineTwoToneIcon />
                  </Grid>
                  <Grid item xs>
                        <Slider value={this.state.rate} aria-labelledby="continuous-slider" max={50} min={-50} step={1} onChange={this.handleRate} />
                  </Grid>
                  <Grid item onClick={ () => { this.setState( {rate: this.state.rate !== undefined ? (this.state.rate < 50 ? this.state.rate + 1 : 50) : PitchDialog.defaultProps.rate }); } }>
                        <AddCircleOutlineTwoToneIcon />
                  </Grid>
                  </Grid>	
                <Typography gutterBottom className="text-center">
                    {this.state.rate}% Rate
                </Typography> 
              </DialogContent>
              <DialogActions>
                <Button onClick={() => { 
                    this.handleCloseAccept(false, false);
                     } } color="primary">
                  Accept
                </Button>
                <Button onClick={() => { this.handleCloseAccept(true, false); }} color="primary">
                  Cancel
                </Button>
                <Button onClick={() => { this.handleCloseAccept(true, true); }} color="primary" >
                  <DeleteForeverIcon /> Remove Custom
                </Button>                

              </DialogActions>
            </Dialog>
          </div>
		);
  }
}

