import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { FetchData } from './components/FetchData';
import { Counter } from './components/Counter';
import LanguageEditor from './EditorPage/Editor';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';
import Header from "./components/Header/Header.js";
import Footer from "./components/Footer/Footer.js";
import GridContainer from "./components/Grid/GridContainer.js";
import GridItem from "./components/Grid/GridItem.js";
import Button from "./components/CustomButtons/Button.js";
import HeaderLinks from "./components/Header/HeaderLinks.js";
import { Fabric, CommandBar } from "@fluentui/react"; 
import Content, { ThemeName } from 'assets/themes/ApplyTheme';
import { TopHeader } from 'components/TopHeader/TopHeader';
import Page from 'components/Page/Page';
import styles from "./assets/jss/material-kit-react/views/landingPage.js";
import './custom.css'


const dashboardRoutes: any[] = ['/counter'];


export default class App extends Component {
  static displayName = App.name;
  render () {
      return (
                <Layout>
                    <Route exact path='/' component={Home} />
                    <Route path='/counter' component={Counter} />
                    <Route path='/editor' component={LanguageEditor} />
                    <AuthorizeRoute path='/fetch-data' component={FetchData} />
                    <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
                </Layout>
    );
  }
}
