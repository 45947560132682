import React, { ChangeEvent } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from "@material-ui/core/FormControl";
import Grid from '@material-ui/core/Grid';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import getAuthorizationHeader from '../Authenticate';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

export interface IPlayerProps  {
    open: boolean,
    src: string,
    play: boolean,
}

export interface IPlayerState extends IPlayerProps {
    waiting: boolean;
}

export interface IOneTimeToken {
    userId: string,
    token: string
}

export const getTempToken = async (): Promise<string> => {
    const Authorization: string = await getAuthorizationHeader();
    const response = await fetch('api/speaker', {
        method: 'POST',
        mode: 'same-origin',
        headers: { Authorization },
        redirect: 'follow',
    });
    if (!response.ok) {
        alert('Something failed ' + response.status);
        throw Error('Unable to get editor value');
    }

    const result: IOneTimeToken = await response.json();
    const url = `/api/speaker?userId=${result.userId}&token=${result.token}`
    return url;
}

export default class PlayerDialog extends React.Component<IPlayerProps, IPlayerState> {

    constructor(props: IPlayerProps)
    {
        super(props);
        this.state = {...props, waiting: true};
    }

    closeEvent = () => {
        this.firstLoad = true;
        this.setState({...this.props, open: false, src: '', waiting: true });
    }

    componentDidUpdate = async (prevProps: IPlayerProps, prevState: IPlayerState) => {
        if(this.state.open == false)
        {
            return;
        }

        if(this.firstLoad)
        {
            this.firstLoad = false;
            const src = await getTempToken();
            this.setState({src, play: true });
        }

    }    
    firstLoad: boolean = true;

    canPlay = (waiting: boolean) => {
        if(waiting === true)
        {
            this.setState({ waiting: false })
        }
    }

    render = () => {
		return (
            <div>
            <Dialog open={this.state.open} onClose={() => this.closeEvent()} aria-labelledby="form-dialog-title">
              <DialogTitle id="form-dialog-title">Text to Speech Player</DialogTitle>
              <DialogContent>
                <Grid container spacing={2}>
                    <Grid item>
                     <FormControl variant="outlined">
                        {
                            (this.state.waiting)
                            ? <CircularProgress color="inherit" />
                            : <></>
                        }
                         <AudioPlayer src={this.state.src} autoPlay={this.state.play} onCanPlay={() => this.canPlay(this.state.waiting)}/>
                     </FormControl>
                    </Grid>
                </Grid>
              </DialogContent>

            </Dialog>
          </div>
		);

    }
}