
//import voices: IVoiceCollection from './voices.json'

export interface IVoice{
    Name: string;
    ShortName: string;
    Type: string;
    NativeName: string;
    IetfName: string;
    Locale: string;
    EnglishName: string;
    Gender: string;
    SampleRateHertz: number;
    StyleList: string[];
    WordsPerMinute: number;
    Status: string;
}

export interface IVoicePair {
    id: string;
    voice: IVoice;
}


export interface ILocale {
    IetfName: string;    
    EnglishName: string;
    NativeName: string;
}

const voices: IVoice[] = require('./voices.json');


export default class Language {

    languages: ILocale[] = [];



    constructor()
    {
        let locales: ILocale[] = [];    
        Object.keys(voices).forEach((key: any) => {
            let found: number = locales.findIndex((element: ILocale): boolean => {
                    return element.IetfName ===voices[key].IetfName;
            })
            if(found === -1)
            {
                locales.push({  IetfName: voices[key].IetfName,    
                    EnglishName: voices[key].EnglishName,
                    NativeName: voices[key].NativeName });
            }
            if (voices[key].Status === "Preview") {
                delete voices[key]
            }
            
        });
        
        this.languages = locales.sort((a, b) => (a.EnglishName.localeCompare(b.EnglishName)) );
    }
    
    public getVoices() {
        return voices;
    }
    
    getVoicesByLocale(locale: string): IVoice[]
    {
        let localeVoices: IVoice[] = [];
        Object.keys(voices).forEach((key: any) => {
            if(locale === voices[key].IetfName)
                localeVoices.push(voices[key]);
        });
        return localeVoices;
    }

}