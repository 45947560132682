import React, { Component, Fragment } from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import authService, { AuthenticationResultStatus } from './AuthorizeService';
import { ApplicationPaths, QueryParameterNames } from './ApiAuthorizationConstants';
//import Button from "components/CustomButtons/Button.js";
import Tooltip from "@material-ui/core/Tooltip";
import { FontIcon } from 'office-ui-fabric-react/lib/Icon';
//import { LocalParking } from '@material-ui/icons';

export class LoginMenu extends Component {
    _subscription;
    _wasmounted = false;

    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: false,
            userName: null
        };

    }



    async componentDidMount() {
        console.log(this._wasmounted ? "It was mounted already" : "It is mounting for the first time" )
        if (!this._wasmounted) {
            this._subscription = authService.subscribe(() => this.populateState());
            this.populateState();
            this._wasmounted = true;
        }
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    async populateState() {
        const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
        console.trace({ populateState: true, isAuthenticated, user });
        this.setState({
            isAuthenticated,
            userName: user && user.name
        });
    }

    render() {
        const { isAuthenticated, userName } = this.state;
        console.trace({ Render: true, isAuthenticated, userName });
        if (!isAuthenticated) {
            const registerPath = `${ApplicationPaths.Register}`;
            const loginPath = `${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${window.location.href}`;
            return this.anonymousView(registerPath, loginPath);
        } else {
            const profilePath = `${ApplicationPaths.Profile}`;
            const logoutPath = { pathname: `${ApplicationPaths.LogOut}`, state: { local: true } };
            return this.authenticatedView(userName, profilePath, logoutPath);
        }
    }

    async signOut(logoutPath) {
        let response = await authService.signOut('/');
        if (response.status === AuthenticationResultStatus.Redirect) {
            window.location = '/';
        }
        
    }

    async signIn() {
        let newUrl = window.location;
        let response = await authService.signIn();
        console.trace(response);
        if (response.status === AuthenticationResultStatus.Redirect) {
            window.location = newUrl;
        }

    }
    authenticatedView(userName, profilePath, logoutPath) {
        return (<Fragment>
            
            <NavItem>
                <Tooltip title="View Profile">
                    <NavLink tag={Link} className="navMenu text-light" to={profilePath}>{userName}</NavLink>
                </Tooltip>
            </NavItem>
            
            <NavItem>
                <NavLink tag={Link} className="navMenu text-light" to={logoutPath}><FontIcon iconName="SignOut" /> Logout</NavLink>
            </NavItem>
            {/*
            <Tooltip title="View Profile">
            <Button
                color="transparent"
                href={profilePath}
                target="_self"
                className={this.props.className}
            >
                {userName}
                </Button>
                </Tooltip>

            <Button
                color="transparent"
                href={`${logoutPath.pathname}?${QueryParameterNames.ReturnUrl}=${window.location.href}`}
                target="_self"
                className={this.props.className}
            >
                Logout
            </Button>

            {/*
            <NavItem>
                <NavLink tag={Link} className="text-dark" to={profilePath}>Hello {userName}</NavLink>
            </NavItem>
            <NavItem>
                <NavLink tag={Link} className="text-dark" to={logoutPath}>Logout</NavLink>
            </NavItem>
            */}
        </Fragment>);

    }

    anonymousView(registerPath, loginPath) {
        return (<Fragment>
            {/*
            <Button
                color="transparent"
                href={registerPath}
                target="_self"
                className={this.props.className}
            >
                Sign Up
            </Button>
            <Button
                color="transparent"
                href={loginPath}
                target="_self"
                className={this.props.className}
            >
                Sign In
            </Button>
            */}

                <NavItem>
                <NavLink tag={Link} className="navMenu text-light" to={registerPath}><FontIcon iconName="AddFriend" /> Register</NavLink>
                </NavItem>
                <NavItem>
                <NavLink tag={Link} className="navMenu text-light" to={loginPath}><FontIcon iconName="Signin" /> Login</NavLink>
                </NavItem>
        </Fragment>);
    }
}
